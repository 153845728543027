.fontSize12 {
    font-size: 12px !important;
}

.fontSize14 {
    font-size: 14px !important;
}

.fontSize16 {
    font-size: 16px !important;
}

.fontSize18 {
    font-size: 18px !important;
}

.fontSize20 {
    font-size: 20px !important;
}

.fontSize22 {
    font-size: 22px !important;
}

.fontSize24 {
    font-size: 24px !important;
}

.fontSize40 {
    font-size: 40px !important;
}

.fontSize48 {
    font-size: 48px !important;
}

.fontSize54 {
    font-size: 54px !important;
}

.fontSize80 {
    font-size: 80px !important;
}

.fontSize126 {
    font-size: 126px !important;
}

.fontSize170 {
    font-size: 170px !important;
}

.lineHeight1 {
    line-height:1.1 !important;
}

.lineHeight3 {
    line-height:1.3 !important;
}

.lineHeight5 {
    line-height:1.5 !important;
}

.serifDisplay {
    font-family: var(--font-2) !important;
}

.sans {
    font-family: var(--font-1) !important;
}