/*

  Theme: Secondary theme

  -----------------------------------
  🚫 DO NOT EDIT THIS FILE DIRECTLY 🚫
  -----------------------------------

  To generate this file:
  1 - Edit `src/themes/secondary-theme` files
  2 - Run `yarn build:themes`

*/

[data-theme="secondary-theme"] {
}
[data-theme="secondary-theme"][data-subtheme="accent"],
[data-theme="secondary-theme"] [data-subtheme="accent"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #00610f;
	--error: #ff005c;
	--focus-1: #28ffff;
	--focus-2: #28ffff;
	/* text */
	--text-1: #010e21;
	--text-2: #616780;
	--text-3: #767d87;
	--text-4: #1510f7;
	--text-5: #038dda;
	--text-6: #0e3e7a;
	--text-7: #45cbb2;
	--text-8: #4ee3c8;
	--text-inverse: #ffffff;
	--text-disabled: #b2c3dc;
	/* icon */
	--icon-1: #010e21;
	--icon-inverse: #ffffff;
	--icon-disabled: #b2c3dc;
	/* backgroundButton */
	--bg-button-1: #1510f7;
	--bg-button-1-hover: #00a3ff;
	--bg-button-1-active: #038dda;
	--bg-button-1-focus: #1510f7;
	--bg-button-1-disabled: #dee8f5;
	--bg-button-3: transparent;
	--bg-button-3-hover: #00a3ff26;
	--bg-button-3-active: #00a3ff;
	--bg-button-3-focus: transparent;
	--bg-button-3-disabled: transparent;
	--bg-link-button-4: transparent;
	--bg-link-button-4-hover: #1510f7;
	--bg-link-button-4-active: transparent;
	--bg-link-button-4-focus: transparent;
	--bg-link-button-4-disabled: transparent;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #3eddbf;
	--border-button-1-disabled: transparent;
	--border-button-3: #1510f7;
	--border-button-3-hover: #1510f7;
	--border-button-3-active: #1510f7;
	--border-button-3-focus: #3eddbf;
	--border-button-3-disabled: #dee8f5;
	--border-button-4: transparent;
	--border-button-4-hover: transparent;
	--border-button-4-active: transparent;
	--border-button-4-focus: #3eddbf;
	--border-button-4-disabled: transparent;
	/* textButton */
	--text-button-1: #ffffff;
	--text-button-1-hover: #ffffff;
	--text-button-1-active: #ffffff;
	--text-button-1-focus: #ffffff;
	--text-button-1-disabled: #b2c3dc;
	--text-button-3: #1510f7;
	--text-button-3-hover: #1510f7;
	--text-button-3-active: #ffffff;
	--text-button-3-focus: #0047ff;
	--text-button-3-disabled: #b2c3dc;
	--text-link-button-4: #1510f7;
	--text-link-button-4-hover: #1510f7;
	--text-link-button-4-active: #1510f7;
	--text-link-button-4-focus: #1510f7;
	--text-link-button-4-disabled: #b2c3dc;
	/* textLink */
	--text-link-1: #1510f7;
	--text-link-1-hover: #00a3ff;
	--text-link-1-active: #038dda;
	--text-link-1-focus: #1510f7;
	--text-link-1-disabled: #b2c3dc;
	--text-link-menu: #010e21;
	--text-link-menu-hover: #1510f7;
	--text-link-menu-active: #201cd4;
	--text-link-menu-focus: #010e21;
	--text-link-menu-disabled: #b2c3dc;
	/* border */
	--border-1: #cfd6e2;
	--border-2: #1510f7;
	/* background */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-2: #f3f3f7;
	--bg-3: #e6e6ef;
	--bg-4: #010e21;
	--bg-disabled: #d1d7e3;
	/* overlay */
	--overlay-1: #08022c66;
	--overlay-2: #08022ccc;
	--overlay-3: #ffffff80;
	--overlay-4: #ffffff66;
	/* brandColors */
	--brand-1: #db005b;
	/* support */
	--success: #ffea96;
	--focus-1: #1510f7;
	--focus-2: #1510f7;
	/* text */
	--text-1: #ffffff;
	--text-2: #eac9d7;
	--text-3: #eac9d7;
	--text-4: #ffea96;
	--text-5: #ffffff;
	--text-6: #ffffff;
	--text-7: #ffffff;
	--text-8: #ffffff;
	--text-inverse: #010d20;
	--text-disabled: #dcdcdc;
	/* icon */
	--icon-1: #ffffff;
	--icon-inverse: #010e21;
	--icon-disabled: #b0b0b0;
	/* backgroundButton */
	--bg-button-1: #ffffff;
	--bg-button-1-hover: #ffdbea;
	--bg-button-1-active: #ffffff99;
	--bg-button-1-focus: #ffffff;
	--bg-button-1-disabled: #b0b0b0;
	--bg-button-3-hover: #ffffff33;
	--bg-button-3-active: #ffdbea;
	--bg-link-button-4-hover: #ffdbea;
	/* borderButton */
	--border-button-1-focus: #1510f7;
	--border-button-3: #ffffff;
	--border-button-3-hover: #ffffff;
	--border-button-3-active: #ffffff;
	--border-button-3-focus: #1510f7;
	--border-button-3-disabled: #b0b0b0;
	--border-button-4-focus: #1510f7;
	/* textButton */
	--text-button-1: #010d20;
	--text-button-1-hover: #010d20;
	--text-button-1-active: #010d20;
	--text-button-1-focus: #010d20;
	--text-button-1-disabled: #dcdcdc;
	--text-button-3: #ffffff;
	--text-button-3-hover: #ffffff;
	--text-button-3-active: #010d20;
	--text-button-3-focus: #ffffff;
	--text-button-3-disabled: #b0b0b0;
	--text-link-button-4: #ffffff;
	--text-link-button-4-hover: #ffdbea;
	--text-link-button-4-active: #ffffff99;
	--text-link-button-4-focus: #ffffff;
	--text-link-button-4-disabled: #b0b0b0;
	/* textLink */
	--text-link-1: #ffffff;
	--text-link-1-hover: #eac9d7;
	--text-link-1-active: #ffffff;
	--text-link-1-focus: #ffffff;
	--text-link-1-disabled: #ffffff4d;
	--text-link-menu: #ffffff;
	--text-link-menu-hover: #ffffff;
	--text-link-menu-active: #ffffff;
	--text-link-menu-focus: #ffffff;
	--text-link-menu-disabled: #ffffff4d;
	/* border */
	--border-1: #ffffff;
	--border-2: #eac9d7;
	/* background */
	--bg-ui: #db005b;
	--bg-1: #db005b;
	--bg-2: #b50052;
	--bg-3: #a8004c;
	--bg-4: #ffffff;
	--bg-disabled: #ffffff99;
	/* overlay */
	--overlay-3: #08022c80;
	--overlay-4: #08022c66;
}
[data-theme="secondary-theme"][data-subtheme="default"],
[data-theme="secondary-theme"] [data-subtheme="default"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #00610f;
	--error: #ff005c;
	--focus-1: #28ffff;
	--focus-2: #28ffff;
	/* text */
	--text-1: #010e21;
	--text-2: #616780;
	--text-3: #767d87;
	--text-4: #1510f7;
	--text-5: #038dda;
	--text-6: #0e3e7a;
	--text-7: #45cbb2;
	--text-8: #4ee3c8;
	--text-inverse: #ffffff;
	--text-disabled: #b2c3dc;
	/* icon */
	--icon-1: #010e21;
	--icon-inverse: #ffffff;
	--icon-disabled: #b2c3dc;
	/* backgroundButton */
	--bg-button-1: #1510f7;
	--bg-button-1-hover: #00a3ff;
	--bg-button-1-active: #038dda;
	--bg-button-1-focus: #1510f7;
	--bg-button-1-disabled: #dee8f5;
	--bg-button-3: transparent;
	--bg-button-3-hover: #00a3ff26;
	--bg-button-3-active: #00a3ff;
	--bg-button-3-focus: transparent;
	--bg-button-3-disabled: transparent;
	--bg-link-button-4: transparent;
	--bg-link-button-4-hover: #1510f7;
	--bg-link-button-4-active: transparent;
	--bg-link-button-4-focus: transparent;
	--bg-link-button-4-disabled: transparent;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #3eddbf;
	--border-button-1-disabled: transparent;
	--border-button-3: #1510f7;
	--border-button-3-hover: #1510f7;
	--border-button-3-active: #1510f7;
	--border-button-3-focus: #3eddbf;
	--border-button-3-disabled: #dee8f5;
	--border-button-4: transparent;
	--border-button-4-hover: transparent;
	--border-button-4-active: transparent;
	--border-button-4-focus: #3eddbf;
	--border-button-4-disabled: transparent;
	/* textButton */
	--text-button-1: #ffffff;
	--text-button-1-hover: #ffffff;
	--text-button-1-active: #ffffff;
	--text-button-1-focus: #ffffff;
	--text-button-1-disabled: #b2c3dc;
	--text-button-3: #1510f7;
	--text-button-3-hover: #1510f7;
	--text-button-3-active: #ffffff;
	--text-button-3-focus: #0047ff;
	--text-button-3-disabled: #b2c3dc;
	--text-link-button-4: #1510f7;
	--text-link-button-4-hover: #1510f7;
	--text-link-button-4-active: #1510f7;
	--text-link-button-4-focus: #1510f7;
	--text-link-button-4-disabled: #b2c3dc;
	/* textLink */
	--text-link-1: #1510f7;
	--text-link-1-hover: #00a3ff;
	--text-link-1-active: #038dda;
	--text-link-1-focus: #1510f7;
	--text-link-1-disabled: #b2c3dc;
	--text-link-menu: #010e21;
	--text-link-menu-hover: #1510f7;
	--text-link-menu-active: #201cd4;
	--text-link-menu-focus: #010e21;
	--text-link-menu-disabled: #b2c3dc;
	/* border */
	--border-1: #cfd6e2;
	--border-2: #1510f7;
	/* background */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-2: #f3f3f7;
	--bg-3: #e6e6ef;
	--bg-4: #010e21;
	--bg-disabled: #d1d7e3;
	/* overlay */
	--overlay-1: #08022c66;
	--overlay-2: #08022ccc;
	--overlay-3: #ffffff80;
	--overlay-4: #ffffff66;
	/* brandColors */
	--brand-1: #db005b;
	/* text */
	--text-5: #010e21;
	--text-6: #010e21;
	--text-7: #010e21;
	--text-8: #010e21;
	/* backgroundButton */
	--bg-button-1: #db005b;
	--bg-button-1-hover: #ff006a;
	--bg-button-1-active: #da035d;
	--bg-button-1-focus: #db005b;
	--bg-button-1-disabled: #f5dee8;
	--bg-button-3-hover: #ff006a33;
	--bg-button-3-active: #ff006a;
	--bg-button-3-disabled: #ffffff;
	--bg-link-button-4-hover: #ff006a;
	/* borderButton */
	--border-button-1-focus: #1510f7;
	--border-button-3: #db005b;
	--border-button-3-hover: #db005b;
	--border-button-3-active: #db005b;
	--border-button-3-focus: #1510f7;
	--border-button-3-disabled: transparent;
	--border-button-4-focus: #1510f7;
	/* textButton */
	--text-button-1-disabled: #dcb2c3;
	--text-button-3: #db005b;
	--text-button-3-hover: #db005b;
	--text-button-3-focus: #ff006a;
	--text-button-3-disabled: #dcb2c3;
	--text-link-button-4: #db005b;
	--text-link-button-4-hover: #ff006a;
	--text-link-button-4-active: #da035d;
	--text-link-button-4-focus: #db005b;
	--text-link-button-4-disabled: #dcb2c3;
	/* textLink */
	--text-link-1: #db005b;
	--text-link-1-hover: #e34e8c;
	--text-link-1-active: #cb457d;
	--text-link-1-focus: #db005b;
	--text-link-1-disabled: #dcb2c3;
	--text-link-menu-hover: #f71070;
	--text-link-menu-active: #d41c69;
	--text-link-menu-disabled: #dcb2c3;
	/* border */
	--border-2: #db005b;
	/* background */
	--bg-2: #f7f3f4;
	--bg-3: #efe6e8;
}
[data-theme="secondary-theme"][data-subtheme="default-alt"],
[data-theme="secondary-theme"] [data-subtheme="default-alt"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #00610f;
	--error: #ff005c;
	--focus-1: #28ffff;
	--focus-2: #28ffff;
	/* text */
	--text-1: #010e21;
	--text-2: #616780;
	--text-3: #767d87;
	--text-4: #1510f7;
	--text-5: #038dda;
	--text-6: #0e3e7a;
	--text-7: #45cbb2;
	--text-8: #4ee3c8;
	--text-inverse: #ffffff;
	--text-disabled: #b2c3dc;
	/* icon */
	--icon-1: #010e21;
	--icon-inverse: #ffffff;
	--icon-disabled: #b2c3dc;
	/* backgroundButton */
	--bg-button-1: #1510f7;
	--bg-button-1-hover: #00a3ff;
	--bg-button-1-active: #038dda;
	--bg-button-1-focus: #1510f7;
	--bg-button-1-disabled: #dee8f5;
	--bg-button-3: transparent;
	--bg-button-3-hover: #00a3ff26;
	--bg-button-3-active: #00a3ff;
	--bg-button-3-focus: transparent;
	--bg-button-3-disabled: transparent;
	--bg-link-button-4: transparent;
	--bg-link-button-4-hover: #1510f7;
	--bg-link-button-4-active: transparent;
	--bg-link-button-4-focus: transparent;
	--bg-link-button-4-disabled: transparent;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #3eddbf;
	--border-button-1-disabled: transparent;
	--border-button-3: #1510f7;
	--border-button-3-hover: #1510f7;
	--border-button-3-active: #1510f7;
	--border-button-3-focus: #3eddbf;
	--border-button-3-disabled: #dee8f5;
	--border-button-4: transparent;
	--border-button-4-hover: transparent;
	--border-button-4-active: transparent;
	--border-button-4-focus: #3eddbf;
	--border-button-4-disabled: transparent;
	/* textButton */
	--text-button-1: #ffffff;
	--text-button-1-hover: #ffffff;
	--text-button-1-active: #ffffff;
	--text-button-1-focus: #ffffff;
	--text-button-1-disabled: #b2c3dc;
	--text-button-3: #1510f7;
	--text-button-3-hover: #1510f7;
	--text-button-3-active: #ffffff;
	--text-button-3-focus: #0047ff;
	--text-button-3-disabled: #b2c3dc;
	--text-link-button-4: #1510f7;
	--text-link-button-4-hover: #1510f7;
	--text-link-button-4-active: #1510f7;
	--text-link-button-4-focus: #1510f7;
	--text-link-button-4-disabled: #b2c3dc;
	/* textLink */
	--text-link-1: #1510f7;
	--text-link-1-hover: #00a3ff;
	--text-link-1-active: #038dda;
	--text-link-1-focus: #1510f7;
	--text-link-1-disabled: #b2c3dc;
	--text-link-menu: #010e21;
	--text-link-menu-hover: #1510f7;
	--text-link-menu-active: #201cd4;
	--text-link-menu-focus: #010e21;
	--text-link-menu-disabled: #b2c3dc;
	/* border */
	--border-1: #cfd6e2;
	--border-2: #1510f7;
	/* background */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-2: #f3f3f7;
	--bg-3: #e6e6ef;
	--bg-4: #010e21;
	--bg-disabled: #d1d7e3;
	/* overlay */
	--overlay-1: #08022c66;
	--overlay-2: #08022ccc;
	--overlay-3: #ffffff80;
	--overlay-4: #ffffff66;
	/* brandColors */
	--brand-1: #db005b;
	/* text */
	--text-5: #010e21;
	--text-6: #010e21;
	--text-7: #010e21;
	--text-8: #010e21;
	/* backgroundButton */
	--bg-button-1: #db005b;
	--bg-button-1-hover: #ff006a;
	--bg-button-1-active: #da035d;
	--bg-button-1-focus: #db005b;
	--bg-button-1-disabled: #f5dee8;
	--bg-button-3-hover: #ff006a33;
	--bg-button-3-active: #ff006a;
	--bg-button-3-disabled: #ffffff;
	--bg-link-button-4-hover: #ff006a;
	/* borderButton */
	--border-button-1-focus: #1510f7;
	--border-button-3: #db005b;
	--border-button-3-hover: #db005b;
	--border-button-3-active: #db005b;
	--border-button-3-focus: #1510f7;
	--border-button-3-disabled: transparent;
	--border-button-4-focus: #1510f7;
	/* textButton */
	--text-button-1-disabled: #dcb2c3;
	--text-button-3: #db005b;
	--text-button-3-hover: #db005b;
	--text-button-3-focus: #ff006a;
	--text-button-3-disabled: #dcb2c3;
	--text-link-button-4: #db005b;
	--text-link-button-4-hover: #ff006a;
	--text-link-button-4-active: #da035d;
	--text-link-button-4-focus: #db005b;
	--text-link-button-4-disabled: #dcb2c3;
	/* textLink */
	--text-link-1: #db005b;
	--text-link-1-hover: #e34e8c;
	--text-link-1-active: #cb457d;
	--text-link-1-focus: #db005b;
	--text-link-1-disabled: #dcb2c3;
	--text-link-menu-hover: #f71070;
	--text-link-menu-active: #d41c69;
	--text-link-menu-disabled: #dcb2c3;
	/* border */
	--border-2: #db005b;
	/* background */
	--bg-ui: #f7f3f4;
	--bg-1: #f7f3f4;
	--bg-2: #ffffff;
	--bg-3: #ffffff;
}
[data-theme="secondary-theme"][data-subtheme="inverse"],
[data-theme="secondary-theme"] [data-subtheme="inverse"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #00610f;
	--error: #ff005c;
	--focus-1: #28ffff;
	--focus-2: #28ffff;
	/* text */
	--text-1: #010e21;
	--text-2: #616780;
	--text-3: #767d87;
	--text-4: #1510f7;
	--text-5: #038dda;
	--text-6: #0e3e7a;
	--text-7: #45cbb2;
	--text-8: #4ee3c8;
	--text-inverse: #ffffff;
	--text-disabled: #b2c3dc;
	/* icon */
	--icon-1: #010e21;
	--icon-inverse: #ffffff;
	--icon-disabled: #b2c3dc;
	/* backgroundButton */
	--bg-button-1: #1510f7;
	--bg-button-1-hover: #00a3ff;
	--bg-button-1-active: #038dda;
	--bg-button-1-focus: #1510f7;
	--bg-button-1-disabled: #dee8f5;
	--bg-button-3: transparent;
	--bg-button-3-hover: #00a3ff26;
	--bg-button-3-active: #00a3ff;
	--bg-button-3-focus: transparent;
	--bg-button-3-disabled: transparent;
	--bg-link-button-4: transparent;
	--bg-link-button-4-hover: #1510f7;
	--bg-link-button-4-active: transparent;
	--bg-link-button-4-focus: transparent;
	--bg-link-button-4-disabled: transparent;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #3eddbf;
	--border-button-1-disabled: transparent;
	--border-button-3: #1510f7;
	--border-button-3-hover: #1510f7;
	--border-button-3-active: #1510f7;
	--border-button-3-focus: #3eddbf;
	--border-button-3-disabled: #dee8f5;
	--border-button-4: transparent;
	--border-button-4-hover: transparent;
	--border-button-4-active: transparent;
	--border-button-4-focus: #3eddbf;
	--border-button-4-disabled: transparent;
	/* textButton */
	--text-button-1: #ffffff;
	--text-button-1-hover: #ffffff;
	--text-button-1-active: #ffffff;
	--text-button-1-focus: #ffffff;
	--text-button-1-disabled: #b2c3dc;
	--text-button-3: #1510f7;
	--text-button-3-hover: #1510f7;
	--text-button-3-active: #ffffff;
	--text-button-3-focus: #0047ff;
	--text-button-3-disabled: #b2c3dc;
	--text-link-button-4: #1510f7;
	--text-link-button-4-hover: #1510f7;
	--text-link-button-4-active: #1510f7;
	--text-link-button-4-focus: #1510f7;
	--text-link-button-4-disabled: #b2c3dc;
	/* textLink */
	--text-link-1: #1510f7;
	--text-link-1-hover: #00a3ff;
	--text-link-1-active: #038dda;
	--text-link-1-focus: #1510f7;
	--text-link-1-disabled: #b2c3dc;
	--text-link-menu: #010e21;
	--text-link-menu-hover: #1510f7;
	--text-link-menu-active: #201cd4;
	--text-link-menu-focus: #010e21;
	--text-link-menu-disabled: #b2c3dc;
	/* border */
	--border-1: #cfd6e2;
	--border-2: #1510f7;
	/* background */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-2: #f3f3f7;
	--bg-3: #e6e6ef;
	--bg-4: #010e21;
	--bg-disabled: #d1d7e3;
	/* overlay */
	--overlay-1: #08022c66;
	--overlay-2: #08022ccc;
	--overlay-3: #ffffff80;
	--overlay-4: #ffffff66;
	/* brandColors */
	--brand-1: #1510f7;
	/* support */
	--success: #8ecc0b;
	/* text */
	--text-1: #ffffff;
	--text-2: #bcc1d4;
	--text-3: #8a91ab;
	--text-5: #00a3ff;
	--text-6: #00b3d8;
	--text-inverse: #010d20;
	--text-disabled: #5377ab;
	/* icon */
	--icon-1: #ffffff;
	--icon-inverse: #010d20;
	--icon-disabled: #5377ab;
	/* backgroundButton */
	--bg-button-1: #ffffff;
	--bg-button-1-hover: #dfdfff;
	--bg-button-1-active: #ffffff;
	--bg-button-1-focus: #ffffff;
	--bg-button-1-disabled: #162740;
	--bg-button-3-hover: #dfdfff33;
	--bg-button-3-active: #dfdfff;
	--bg-link-button-4-hover: #3eddbf;
	/* borderButton */
	--border-button-3: #ffffff;
	--border-button-3-hover: #ffffff;
	--border-button-3-active: #ffffff;
	--border-button-3-disabled: #162740;
	/* textButton */
	--text-button-1: #1510f7;
	--text-button-1-hover: #1510f7;
	--text-button-1-active: #1510f7;
	--text-button-1-focus: #1510f7;
	--text-button-1-disabled: #7a8fae;
	--text-button-3: #ffffff;
	--text-button-3-hover: #ffffff;
	--text-button-3-active: #1510f7;
	--text-button-3-focus: #ffffff;
	--text-button-3-disabled: #7a8fae;
	--text-link-button-4: #3eddbf;
	--text-link-button-4-hover: #3eddbf;
	--text-link-button-4-active: #3eddbf;
	--text-link-button-4-focus: #3eddbf;
	--text-link-button-4-disabled: #7a8fae;
	/* textLink */
	--text-link-1: #82fff7;
	--text-link-1-hover: #64ccc6;
	--text-link-1-active: #82fff7;
	--text-link-1-focus: #82fff7;
	--text-link-1-disabled: #7a8fae;
	--text-link-menu: #ffffff;
	--text-link-menu-hover: #00b3d8;
	--text-link-menu-active: #0897b4;
	--text-link-menu-focus: #ffffff;
	--text-link-menu-disabled: #7a8fae;
	/* border */
	--border-1: #626e84;
	--border-2: #cfd6e2;
	/* background */
	--bg-ui: #010d20;
	--bg-1: #010d20;
	--bg-2: #0c192e;
	--bg-3: #1c2d46;
	--bg-4: #ffffff;
	--bg-disabled: #5377ab;
	/* overlay */
	--overlay-1: #3f3c5366;
	--overlay-2: #d9dde9cc;
	--overlay-3: #000a2599;
	--overlay-4: #000a2566;
	/* brandColors */
	--brand-1: #db005b;
	/* text */
	--text-4: #db005b;
	/* textButton */
	--text-button-1: #db005b;
	--text-button-1-hover: #db005b;
	--text-button-1-active: #db005b;
	--text-button-1-focus: #db005b;
	--text-button-3-active: #db005b;
}
