/* global scope styles */
:root {
	font-family: var(--font-1);
	word-break: break-word;
}

.container {
	margin-inline: auto;
	width: 100%;
	max-width: var(--mobile-max-width);
	padding-inline: var(--spc-2xs);

	@media (--tablet) {
		max-width: var(--tablet-max-width);
		padding-inline: var(--spc-l);
	}

	@media (--desktop) {
		max-width: var(--desktop-max-width);
		padding-inline: var(--spc-4xl);
	}

	@media (--big-screen) {
		max-width: var(--big-screen-max-width);
		padding-inline: 0;
	}
}

.debug {
	border: 4px dashed red;
}

/* DEMO THEME */

em {
	font-style: italic !important;
}
