/*

  Theme: Global theme

  -----------------------------------
  🚫 DO NOT EDIT THIS FILE DIRECTLY 🚫
  -----------------------------------

  To generate this file:
  1 - Edit `src/themes/global-theme` files
  2 - Run `yarn build:themes`

*/

@custom-media --tablet (min-width: 700px);
@custom-media --desktop (min-width: 1025px);
@custom-media --big-screen (min-width: 1681px);

:root {
	/* fontFamily */
	--font-1: DM Sans;
	--font-2: DM Serif Display;
	/* containers */
	--mobile-max-width: 100%;
	--tablet-max-width: 100%;
	--desktop-max-width: 100%;
	--big-screen-max-width: 1568px;
	/* spacing */
	--spc-4xs: 4px;
	--spc-3xs: 8px;
	--spc-2xs: 16px;
	--spc-xs: 24px;
	--spc-s: 32px;
	--spc-m: 40px;
	--spc-l: 48px;
	--spc-xl: 56px;
	--spc-2xl: 64px;
	--spc-3xl: 80px;
	--spc-4xl: 96px;
	--spc-5xl: 128px;
	/* opacity */
	--opacity-1: 0.08;
	--opacity-2: 0.3;
	--opacity-3: 0.4;
	--opacity-4: 0.56;
	--opacity-5: 0.64;
	--opacity-6: 0.72;
	/* sizes */
	--size-2xs: 8px;
	--size-xs: 16px;
	--size-sm: 24px;
	--size-md: 32px;
	--size-ml: 40px;
	--size-lg: 48px;
	--size-xl: 56px;
	--size-2xl: 64px;
	/* columns */
	--col-1: 8.33%;
	--col-2: 16.67%;
	--col-3: 25%;
	--col-4: 33.33%;
	--col-5: 41.67%;
	--col-6: 50%;
	--col-7: 58.33%;
	--col-8: 66.67%;
	--col-9: 75%;
	--col-10: 83.33%;
	--col-11: 91.67%;
	--col-12: 100%;
	/* boxShadow */
	--box-shadow-sm: 0px 4px 18px rgba(var(--shadow-1), 0.08);
	--box-shadow-md: 0px 5px 26px rgba(var(--shadow-1), 0.12);
	--box-shadow-lg: 0px 20px 34px rgba(var(--shadow-1), 0.1);
	--box-shadow-xl: 0px 18px 26px rgba(var(--shadow-1), 0.2);
	/* darkShadow */
	--dark-shadow-sm: 0px 4px 16px rgba(var(--shadow-2), 0.6);
	--dark-shadow-md: 0px 5px 26px rgba(var(--shadow-2), 0.72);
	--dark-shadow-lg: 0px 20px 34px rgba(var(--shadow-2), 0.7);
	--dark-shadow-xl: 0px 19px 26px rgba(var(--shadow-2), 0.7);
}
