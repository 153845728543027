/*

  Theme: Main theme

  -----------------------------------
  🚫 DO NOT EDIT THIS FILE DIRECTLY 🚫
  -----------------------------------

  To generate this file:
  1 - Edit `src/themes/main-theme` files
  2 - Run `yarn build:themes`

*/

[data-theme="main-theme"] {
}
[data-theme="main-theme"][data-subtheme="accent"],
[data-theme="main-theme"] [data-subtheme="accent"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #00610f;
	--error: #ff005c;
	--focus-1: #28ffff;
	--focus-2: #28ffff;
	/* text */
	--text-1: #010e21;
	--text-2: #616780;
	--text-3: #767d87;
	--text-4: #1510f7;
	--text-5: #038dda;
	--text-6: #0e3e7a;
	--text-7: #45cbb2;
	--text-8: #4ee3c8;
	--text-inverse: #ffffff;
	--text-disabled: #b2c3dc;
	/* icon */
	--icon-1: #010e21;
	--icon-inverse: #ffffff;
	--icon-disabled: #b2c3dc;
	/* backgroundButton */
	--bg-button-1: #1510f7;
	--bg-button-1-hover: #00a3ff;
	--bg-button-1-active: #038dda;
	--bg-button-1-focus: #1510f7;
	--bg-button-1-disabled: #dee8f5;
	--bg-button-3: transparent;
	--bg-button-3-hover: #00a3ff26;
	--bg-button-3-active: #00a3ff;
	--bg-button-3-focus: transparent;
	--bg-button-3-disabled: transparent;
	--bg-link-button-4: transparent;
	--bg-link-button-4-hover: #1510f7;
	--bg-link-button-4-active: transparent;
	--bg-link-button-4-focus: transparent;
	--bg-link-button-4-disabled: transparent;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #3eddbf;
	--border-button-1-disabled: transparent;
	--border-button-3: #1510f7;
	--border-button-3-hover: #1510f7;
	--border-button-3-active: #1510f7;
	--border-button-3-focus: #3eddbf;
	--border-button-3-disabled: #dee8f5;
	--border-button-4: transparent;
	--border-button-4-hover: transparent;
	--border-button-4-active: transparent;
	--border-button-4-focus: #3eddbf;
	--border-button-4-disabled: transparent;
	/* textButton */
	--text-button-1: #ffffff;
	--text-button-1-hover: #ffffff;
	--text-button-1-active: #ffffff;
	--text-button-1-focus: #ffffff;
	--text-button-1-disabled: #b2c3dc;
	--text-button-3: #1510f7;
	--text-button-3-hover: #1510f7;
	--text-button-3-active: #ffffff;
	--text-button-3-focus: #0047ff;
	--text-button-3-disabled: #b2c3dc;
	--text-link-button-4: #1510f7;
	--text-link-button-4-hover: #1510f7;
	--text-link-button-4-active: #1510f7;
	--text-link-button-4-focus: #1510f7;
	--text-link-button-4-disabled: #b2c3dc;
	/* textLink */
	--text-link-1: #1510f7;
	--text-link-1-hover: #00a3ff;
	--text-link-1-active: #038dda;
	--text-link-1-focus: #1510f7;
	--text-link-1-disabled: #b2c3dc;
	--text-link-menu: #010e21;
	--text-link-menu-hover: #1510f7;
	--text-link-menu-active: #201cd4;
	--text-link-menu-focus: #010e21;
	--text-link-menu-disabled: #b2c3dc;
	/* border */
	--border-1: #cfd6e2;
	--border-2: #1510f7;
	/* background */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-2: #f3f3f7;
	--bg-3: #e6e6ef;
	--bg-4: #010e21;
	--bg-disabled: #d1d7e3;
	/* overlay */
	--overlay-1: #08022c66;
	--overlay-2: #08022ccc;
	--overlay-3: #ffffff80;
	--overlay-4: #ffffff66;
	/* brandColors */
	--brand-1: #1510f7;
	/* support */
	--success: #8ecc0b;
	/* text */
	--text-1: #ffffff;
	--text-2: #c9d8ea;
	--text-3: #9faeff;
	--text-4: #55fbdc;
	--text-5: #4b7fca;
	--text-6: #00b3d8;
	--text-inverse: #010d20;
	--text-disabled: #4b7fca;
	/* icon */
	--icon-1: #ffffff;
	--icon-inverse: #55fbdc;
	--icon-disabled: #4b7fca;
	/* backgroundButton */
	--bg-button-1: #ffffff;
	--bg-button-1-hover: #e8e7fe;
	--bg-button-1-active: #e8e7fe;
	--bg-button-1-focus: #ffffff;
	--bg-button-1-disabled: #6571df;
	--bg-button-3-hover: #ffffff33;
	--bg-button-3-active: #e8e7fe;
	--bg-link-button-4-hover: #3eddbf;
	/* borderButton */
	--border-button-1-active: #1b3870;
	--border-button-3: #ffffff;
	--border-button-3-hover: #ffffff;
	--border-button-3-active: #ffffff;
	--border-button-3-disabled: #6571df;
	/* textButton */
	--text-button-1: #1510f7;
	--text-button-1-hover: #1510f7;
	--text-button-1-active: #1b3870;
	--text-button-1-focus: #1510f7;
	--text-button-1-disabled: #8daedf;
	--text-button-3: #ffffff;
	--text-button-3-hover: #ffffff;
	--text-button-3-active: #1b3870;
	--text-button-3-focus: #ffffff;
	--text-button-3-disabled: #8daedf;
	--text-link-button-4: #3eddbf;
	--text-link-button-4-hover: #3eddbf;
	--text-link-button-4-active: #3eddbf;
	--text-link-button-4-focus: #3eddbf;
	--text-link-button-4-disabled: #8daedf;
	/* textLink */
	--text-link-1: #82fff7;
	--text-link-1-hover: #64ccc6;
	--text-link-1-active: #82fff7;
	--text-link-1-focus: #82fff7;
	--text-link-1-disabled: #8daedf;
	--text-link-menu: #ffffff;
	--text-link-menu-hover: #82fff7;
	--text-link-menu-active: #63e8e0;
	--text-link-menu-focus: #ffffff;
	--text-link-menu-disabled: #8daedf;
	/* border */
	--border-1: #605cff;
	--border-2: #55fbdc;
	/* background */
	--bg-ui: #1510f7;
	--bg-1: #1510f7;
	--bg-2: #4642ff;
	--bg-3: #6966fa;
	--bg-4: #ffffff;
	--bg-disabled: #6571df;
	/* overlay */
	--overlay-1: #3946fa66;
	--overlay-2: #030f46cc;
	--overlay-3: #000a2580;
	--overlay-4: #000a2566;
}
[data-theme="main-theme"][data-subtheme="default"],
[data-theme="main-theme"] [data-subtheme="default"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #00610f;
	--error: #ff005c;
	--focus-1: #28ffff;
	--focus-2: #28ffff;
	/* text */
	--text-1: #010e21;
	--text-2: #616780;
	--text-3: #767d87;
	--text-4: #1510f7;
	--text-5: #038dda;
	--text-6: #0e3e7a;
	--text-7: #45cbb2;
	--text-8: #4ee3c8;
	--text-inverse: #ffffff;
	--text-disabled: #b2c3dc;
	/* icon */
	--icon-1: #010e21;
	--icon-inverse: #ffffff;
	--icon-disabled: #b2c3dc;
	/* backgroundButton */
	--bg-button-1: #1510f7;
	--bg-button-1-hover: #00a3ff;
	--bg-button-1-active: #038dda;
	--bg-button-1-focus: #1510f7;
	--bg-button-1-disabled: #dee8f5;
	--bg-button-3: transparent;
	--bg-button-3-hover: #00a3ff26;
	--bg-button-3-active: #00a3ff;
	--bg-button-3-focus: transparent;
	--bg-button-3-disabled: transparent;
	--bg-link-button-4: transparent;
	--bg-link-button-4-hover: #1510f7;
	--bg-link-button-4-active: transparent;
	--bg-link-button-4-focus: transparent;
	--bg-link-button-4-disabled: transparent;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #3eddbf;
	--border-button-1-disabled: transparent;
	--border-button-3: #1510f7;
	--border-button-3-hover: #1510f7;
	--border-button-3-active: #1510f7;
	--border-button-3-focus: #3eddbf;
	--border-button-3-disabled: #dee8f5;
	--border-button-4: transparent;
	--border-button-4-hover: transparent;
	--border-button-4-active: transparent;
	--border-button-4-focus: #3eddbf;
	--border-button-4-disabled: transparent;
	/* textButton */
	--text-button-1: #ffffff;
	--text-button-1-hover: #ffffff;
	--text-button-1-active: #ffffff;
	--text-button-1-focus: #ffffff;
	--text-button-1-disabled: #b2c3dc;
	--text-button-3: #1510f7;
	--text-button-3-hover: #1510f7;
	--text-button-3-active: #ffffff;
	--text-button-3-focus: #0047ff;
	--text-button-3-disabled: #b2c3dc;
	--text-link-button-4: #1510f7;
	--text-link-button-4-hover: #1510f7;
	--text-link-button-4-active: #1510f7;
	--text-link-button-4-focus: #1510f7;
	--text-link-button-4-disabled: #b2c3dc;
	/* textLink */
	--text-link-1: #1510f7;
	--text-link-1-hover: #00a3ff;
	--text-link-1-active: #038dda;
	--text-link-1-focus: #1510f7;
	--text-link-1-disabled: #b2c3dc;
	--text-link-menu: #010e21;
	--text-link-menu-hover: #1510f7;
	--text-link-menu-active: #201cd4;
	--text-link-menu-focus: #010e21;
	--text-link-menu-disabled: #b2c3dc;
	/* border */
	--border-1: #cfd6e2;
	--border-2: #1510f7;
	/* background */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-2: #f3f3f7;
	--bg-3: #e6e6ef;
	--bg-4: #010e21;
	--bg-disabled: #d1d7e3;
	/* overlay */
	--overlay-1: #08022c66;
	--overlay-2: #08022ccc;
	--overlay-3: #ffffff80;
	--overlay-4: #ffffff66;
	/* brandColors */
	--brand-1: #1510f7;
}
[data-theme="main-theme"][data-subtheme="default-alt"],
[data-theme="main-theme"] [data-subtheme="default-alt"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #00610f;
	--error: #ff005c;
	--focus-1: #28ffff;
	--focus-2: #28ffff;
	/* text */
	--text-1: #010e21;
	--text-2: #616780;
	--text-3: #767d87;
	--text-4: #1510f7;
	--text-5: #038dda;
	--text-6: #0e3e7a;
	--text-7: #45cbb2;
	--text-8: #4ee3c8;
	--text-inverse: #ffffff;
	--text-disabled: #b2c3dc;
	/* icon */
	--icon-1: #010e21;
	--icon-inverse: #ffffff;
	--icon-disabled: #b2c3dc;
	/* backgroundButton */
	--bg-button-1: #1510f7;
	--bg-button-1-hover: #00a3ff;
	--bg-button-1-active: #038dda;
	--bg-button-1-focus: #1510f7;
	--bg-button-1-disabled: #dee8f5;
	--bg-button-3: transparent;
	--bg-button-3-hover: #00a3ff26;
	--bg-button-3-active: #00a3ff;
	--bg-button-3-focus: transparent;
	--bg-button-3-disabled: transparent;
	--bg-link-button-4: transparent;
	--bg-link-button-4-hover: #1510f7;
	--bg-link-button-4-active: transparent;
	--bg-link-button-4-focus: transparent;
	--bg-link-button-4-disabled: transparent;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #3eddbf;
	--border-button-1-disabled: transparent;
	--border-button-3: #1510f7;
	--border-button-3-hover: #1510f7;
	--border-button-3-active: #1510f7;
	--border-button-3-focus: #3eddbf;
	--border-button-3-disabled: #dee8f5;
	--border-button-4: transparent;
	--border-button-4-hover: transparent;
	--border-button-4-active: transparent;
	--border-button-4-focus: #3eddbf;
	--border-button-4-disabled: transparent;
	/* textButton */
	--text-button-1: #ffffff;
	--text-button-1-hover: #ffffff;
	--text-button-1-active: #ffffff;
	--text-button-1-focus: #ffffff;
	--text-button-1-disabled: #b2c3dc;
	--text-button-3: #1510f7;
	--text-button-3-hover: #1510f7;
	--text-button-3-active: #ffffff;
	--text-button-3-focus: #0047ff;
	--text-button-3-disabled: #b2c3dc;
	--text-link-button-4: #1510f7;
	--text-link-button-4-hover: #1510f7;
	--text-link-button-4-active: #1510f7;
	--text-link-button-4-focus: #1510f7;
	--text-link-button-4-disabled: #b2c3dc;
	/* textLink */
	--text-link-1: #1510f7;
	--text-link-1-hover: #00a3ff;
	--text-link-1-active: #038dda;
	--text-link-1-focus: #1510f7;
	--text-link-1-disabled: #b2c3dc;
	--text-link-menu: #010e21;
	--text-link-menu-hover: #1510f7;
	--text-link-menu-active: #201cd4;
	--text-link-menu-focus: #010e21;
	--text-link-menu-disabled: #b2c3dc;
	/* border */
	--border-1: #cfd6e2;
	--border-2: #1510f7;
	/* background */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-2: #f3f3f7;
	--bg-3: #e6e6ef;
	--bg-4: #010e21;
	--bg-disabled: #d1d7e3;
	/* overlay */
	--overlay-1: #08022c66;
	--overlay-2: #08022ccc;
	--overlay-3: #ffffff80;
	--overlay-4: #ffffff66;
	/* brandColors */
	--brand-1: #e6f1f8;
	/* background */
	--bg-ui: #f3f3f7;
	--bg-1: #f3f3f7;
	--bg-2: #ffffff;
	--bg-3: #ffffff;
}
[data-theme="main-theme"][data-subtheme="inverse"],
[data-theme="main-theme"] [data-subtheme="inverse"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #00610f;
	--error: #ff005c;
	--focus-1: #28ffff;
	--focus-2: #28ffff;
	/* text */
	--text-1: #010e21;
	--text-2: #616780;
	--text-3: #767d87;
	--text-4: #1510f7;
	--text-5: #038dda;
	--text-6: #0e3e7a;
	--text-7: #45cbb2;
	--text-8: #4ee3c8;
	--text-inverse: #ffffff;
	--text-disabled: #b2c3dc;
	/* icon */
	--icon-1: #010e21;
	--icon-inverse: #ffffff;
	--icon-disabled: #b2c3dc;
	/* backgroundButton */
	--bg-button-1: #1510f7;
	--bg-button-1-hover: #00a3ff;
	--bg-button-1-active: #038dda;
	--bg-button-1-focus: #1510f7;
	--bg-button-1-disabled: #dee8f5;
	--bg-button-3: transparent;
	--bg-button-3-hover: #00a3ff26;
	--bg-button-3-active: #00a3ff;
	--bg-button-3-focus: transparent;
	--bg-button-3-disabled: transparent;
	--bg-link-button-4: transparent;
	--bg-link-button-4-hover: #1510f7;
	--bg-link-button-4-active: transparent;
	--bg-link-button-4-focus: transparent;
	--bg-link-button-4-disabled: transparent;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #3eddbf;
	--border-button-1-disabled: transparent;
	--border-button-3: #1510f7;
	--border-button-3-hover: #1510f7;
	--border-button-3-active: #1510f7;
	--border-button-3-focus: #3eddbf;
	--border-button-3-disabled: #dee8f5;
	--border-button-4: transparent;
	--border-button-4-hover: transparent;
	--border-button-4-active: transparent;
	--border-button-4-focus: #3eddbf;
	--border-button-4-disabled: transparent;
	/* textButton */
	--text-button-1: #ffffff;
	--text-button-1-hover: #ffffff;
	--text-button-1-active: #ffffff;
	--text-button-1-focus: #ffffff;
	--text-button-1-disabled: #b2c3dc;
	--text-button-3: #1510f7;
	--text-button-3-hover: #1510f7;
	--text-button-3-active: #ffffff;
	--text-button-3-focus: #0047ff;
	--text-button-3-disabled: #b2c3dc;
	--text-link-button-4: #1510f7;
	--text-link-button-4-hover: #1510f7;
	--text-link-button-4-active: #1510f7;
	--text-link-button-4-focus: #1510f7;
	--text-link-button-4-disabled: #b2c3dc;
	/* textLink */
	--text-link-1: #1510f7;
	--text-link-1-hover: #00a3ff;
	--text-link-1-active: #038dda;
	--text-link-1-focus: #1510f7;
	--text-link-1-disabled: #b2c3dc;
	--text-link-menu: #010e21;
	--text-link-menu-hover: #1510f7;
	--text-link-menu-active: #201cd4;
	--text-link-menu-focus: #010e21;
	--text-link-menu-disabled: #b2c3dc;
	/* border */
	--border-1: #cfd6e2;
	--border-2: #1510f7;
	/* background */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-2: #f3f3f7;
	--bg-3: #e6e6ef;
	--bg-4: #010e21;
	--bg-disabled: #d1d7e3;
	/* overlay */
	--overlay-1: #08022c66;
	--overlay-2: #08022ccc;
	--overlay-3: #ffffff80;
	--overlay-4: #ffffff66;
	/* brandColors */
	--brand-1: #1510f7;
	/* support */
	--success: #8ecc0b;
	/* text */
	--text-1: #ffffff;
	--text-2: #bcc1d4;
	--text-3: #8a91ab;
	--text-5: #00a3ff;
	--text-6: #00b3d8;
	--text-inverse: #010d20;
	--text-disabled: #5377ab;
	/* icon */
	--icon-1: #ffffff;
	--icon-inverse: #010d20;
	--icon-disabled: #5377ab;
	/* backgroundButton */
	--bg-button-1: #ffffff;
	--bg-button-1-hover: #dfdfff;
	--bg-button-1-active: #ffffff;
	--bg-button-1-focus: #ffffff;
	--bg-button-1-disabled: #162740;
	--bg-button-3-hover: #dfdfff33;
	--bg-button-3-active: #dfdfff;
	--bg-link-button-4-hover: #3eddbf;
	/* borderButton */
	--border-button-3: #ffffff;
	--border-button-3-hover: #ffffff;
	--border-button-3-active: #ffffff;
	--border-button-3-disabled: #162740;
	/* textButton */
	--text-button-1: #1510f7;
	--text-button-1-hover: #1510f7;
	--text-button-1-active: #1510f7;
	--text-button-1-focus: #1510f7;
	--text-button-1-disabled: #7a8fae;
	--text-button-3: #ffffff;
	--text-button-3-hover: #ffffff;
	--text-button-3-active: #1510f7;
	--text-button-3-focus: #ffffff;
	--text-button-3-disabled: #7a8fae;
	--text-link-button-4: #3eddbf;
	--text-link-button-4-hover: #3eddbf;
	--text-link-button-4-active: #3eddbf;
	--text-link-button-4-focus: #3eddbf;
	--text-link-button-4-disabled: #7a8fae;
	/* textLink */
	--text-link-1: #82fff7;
	--text-link-1-hover: #64ccc6;
	--text-link-1-active: #82fff7;
	--text-link-1-focus: #82fff7;
	--text-link-1-disabled: #7a8fae;
	--text-link-menu: #ffffff;
	--text-link-menu-hover: #00b3d8;
	--text-link-menu-active: #0897b4;
	--text-link-menu-focus: #ffffff;
	--text-link-menu-disabled: #7a8fae;
	/* border */
	--border-1: #626e84;
	--border-2: #cfd6e2;
	/* background */
	--bg-ui: #010d20;
	--bg-1: #010d20;
	--bg-2: #0c192e;
	--bg-3: #1c2d46;
	--bg-4: #ffffff;
	--bg-disabled: #5377ab;
	/* overlay */
	--overlay-1: #3f3c5366;
	--overlay-2: #d9dde9cc;
	--overlay-3: #000a2599;
	--overlay-4: #000a2566;
}
